enum ThemeColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
    SALE = 'sale',
    RENTAL = 'rental',
}

export default ThemeColor;
