import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import ToolCard from '../../ui/ToolCard';

interface ToolCategoryProps {
    toolCategory: ToolCategoryResponse;
}

function ToolCategory({ toolCategory }: ToolCategoryProps) {
    const navigate = useNavigate();
    const handleClick = useCallback(() => {
        navigate(`/rubriques/${getIdFromIri(entityToId(toolCategory))}`);
    }, [navigate, toolCategory]);

    return (
        <ToolCard
            icon={FolderOpenIcon}
            color={toolCategory.color || ThemeColor.PRIMARY}
            buttonText="Voir les outils"
            label={toolCategory.label}
            onClick={handleClick}
        />
    );
}

export default ToolCategory;
