import React from 'react';
import { Grid, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useParams } from 'react-router-dom';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import Tool from '../features/tools/Tool';
import useEditMode from '../features/template/useEditMode';
import useAgency from '../features/agency/useAgency';
import useSelectedAgency from '../features/agency/useSelectedAgency';
import ToolCardSkeleton from '../ui/ToolCardSkeleton';
import Breadcrumb from '../features/breadcrumb/Breadcrumb';
import useToolCategory from '../features/toolsCategory/useToolCategory';
import useVisibleTools from '../features/tools/useVisibleTools';

const itemSize: number = 3; // sur 12

const previousPages = [
    {
        label: 'Outils',
        path: '/rubriques',
    },
];

function Tools() {
    const [editMode] = useEditMode();
    const agency = useAgency(useSelectedAgency()[0]);

    const { toolCategoryId } = useParams();
    const toolCategoryIri = `/tool-categories/${toolCategoryId}`;

    const currentToolCategory = useToolCategory(toolCategoryIri);

    const visibleTools = useVisibleTools({
        toolCategory: toolCategoryIri,
        editMode,
        agency,
    });

    return (
        <>
            <Typography variant="h1" component="h1" sx={visuallyHidden}>
                Outils {currentToolCategory?.label}
            </Typography>
            <Breadcrumb
                previousPages={previousPages}
                currentPage={
                    currentToolCategory?.label
                        ? currentToolCategory?.label
                        : 'outils'
                }
            />

            <Grid container spacing={4} sx={{ mt: 2, mb: 2 }}>
                {visibleTools?.map((tool) => (
                    <Grid key={tool.id} item xs={itemSize}>
                        <Tool
                            tool={tool}
                            color={
                                currentToolCategory?.color || ThemeColor.PRIMARY
                            }
                        />
                    </Grid>
                ))}
                {!visibleTools &&
                    [1, 2].map((key) => (
                        <Grid key={key} item xs={itemSize}>
                            <ToolCardSkeleton />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
}

export default Tools;
