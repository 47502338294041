import { Theme, createTheme, lighten, darken } from '@mui/material';
import { frFR } from '@mui/material/locale';

// doc : https://mui.com/customization/theming/
// valeurs par défaut : https://mui.com/customization/default-theme/
// couleurs : https://mui.com/customization/color/

export const headerHeight: string = '97px';

/**
 * Ajoute les style de sale, rental à la palette
 */
declare module '@mui/material/styles' {
    interface Palette {
        sale: Palette['primary'];
        rental: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        sale?: PaletteOptions['primary'];
        rental?: PaletteOptions['primary'];
    }
}

// Met à jour les props de style des Boutons
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        rental: true;
        sale: true;
    }
}

// Met à jour les props de style des SvgIcon
declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        rental: true;
        sale: true;
    }
}

// Met à jour les props de style des LinearProgress
declare module '@mui/material/LinearProgress' {
    interface LinearProgressPropsColorOverrides {
        rental: true;
        sale: true;
    }
}

declare module '@mui/material/styles' {
    interface TypeBackground {
        rainbow: string;
    }
}

const baseTheme: Theme = createTheme(
    {
        palette: {
            text: {
                primary: '#000000',
                secondary: '#333333',
            },
            primary: {
                main: '#1C5EEB',
                light: '#6D9EFF',
                dark: '#143d8a',
                contrastText: '#fff',
            },
            secondary: {
                main: '#ff6f00',
                light: lighten('#ff6f00', 0.3),
                dark: darken('#ff6f00', 0.3),
                contrastText: '#fff',
            },
            rental: {
                main: '#048c41',
                light: lighten('#048c41', 0.3),
                dark: darken('#048c41', 0.3),
                contrastText: '#fff',
            },
            sale: {
                // pareil que primary
                main: '#1C5EEB',
                light: '#6D9EFF',
                dark: darken('#1C5EEB', 0.3),
                contrastText: '#fff',
            },
            background: {
                paper: '#fff',
                default: '#f5f4f3',
                rainbow: 'linear-gradient(to right, #2ac1ff, #adffe3)',
            },
            success: {
                main: '#43a148',
                light: lighten('#43a148', 0.3),
                dark: darken('#43a148', 0.3),
            },
        },
        shape: {
            borderRadius: 20,
        },
        typography: {
            fontFamily: 'IBM Plex Sans Condensed',
        },
    },
    frFR
);

const theme = createTheme(baseTheme, {
    typography: {
        h1: {
            fontSize: '3rem',
            fontWeight: 'bold',
            marginTop: baseTheme.spacing(2),
            marginBottom: baseTheme.spacing(2),
            color: baseTheme.palette.text.primary,
            fontFamily: 'Lexend',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 'bold',
            marginTop: baseTheme.spacing(2),
            marginBottom: baseTheme.spacing(2),
            color: baseTheme.palette.text.primary,
            fontFamily: 'Lexend',
        },
        h3: {
            color: baseTheme.palette.text.primary,
            fontSize: '1.6rem',
            fontWeight: 'bold',
            fontFamily: 'Lexend',
        },
        h4: {
            color: baseTheme.palette.text.primary,
            fontSize: '1.3rem',
            fontWeight: '600',
            fontFamily: 'Lexend',
        },
        h5: {
            color: baseTheme.palette.text.primary,
            fontFamily: 'Lexend',
            fontSize: '1.2rem',
            fontWeight: '600',
        },
        h6: {
            color: baseTheme.palette.text.primary,
            fontFamily: 'Lexend',
            fontSize: '1.1rem',
            fontWeight: '600',
        },
        body1: {
            color: baseTheme.palette.text.primary,
        },
        body2: {
            color: baseTheme.palette.text.primary,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    [baseTheme.breakpoints.down('lg')]: {
                        minHeight: '5px',
                    },
                    [baseTheme.breakpoints.up('lg')]: {
                        minHeight: '8px',
                    },
                    margin: 0,
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: 0,
                },

                label: {
                    '&.Mui-active': {
                        '& .MuiButton-root': {
                            color: baseTheme.palette.secondary.light,
                        },
                    },

                    '&.Mui-error': {
                        '& .MuiButton-root': {
                            color: baseTheme.palette.error.light,
                        },
                    },

                    '& .MuiButton-root': {
                        color: baseTheme.palette.grey[500],
                        minWidth: 0,
                        justifyContent: 'flex-start',
                        whiteSpace: 'normal',
                    },
                    transition: '0.5s',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-root': {
                        color: baseTheme.palette.primary.dark,
                        // On grossi la taille du label quand il est remonté.
                        '&.MuiFormLabel-filled, &.Mui-focused, &.MuiInputLabel-shrink':
                            {
                                fontSize: '1.3rem',
                            },
                    },
                    '& .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                        {
                            // Pour que la bordure coupée par le label remonté fasse la même taille que le label qu'on a grossi.
                            fontSize: '1.3rem',
                        },
                },
            },
        },
    },
});

export default theme;
