import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetUserResponse from '@acdc/shared/src/features/user/GetUserResponse';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import CONFIGS from '../../configs';

export const GET_AUTHENTICATED_USER = gql`
    query GetAuthenticatedUser($userId: ID!) {
        user(id: $userId) {
            id
            code
            firstname
            lastname
            email
            phone
            profession
            comment
            photo {
                id
                publicPath
            }
        }
    }
`;

const useLoadAuthenticatedUser = () => {
    const [currentUser] = usePersistentState(
        CONFIGS.userDataStorageKeys.currentUser
    );

    const setAlert = useAlert();
    const { data, error } = useQuery<GetUserResponse>(GET_AUTHENTICATED_USER, {
        ...persistentQueryOptions,
        variables: {
            userId: entityToId(currentUser),
        },
    });

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement de l'utilisateur.",
                'error'
            );
        }
    }, [error, setAlert]);

    return data?.user || undefined;
};

export default useLoadAuthenticatedUser;
