import { Box, LinearProgress, Typography, Theme, Step } from '@mui/material';
import React from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import type { LinkItem } from './Menu';
import useEditMode from '../template/useEditMode';
import StatusPage from './StatusPage';
import EditContentLink from './EditContentLink';
import Presentation from '../presentation/Presentation';
import MenuStepper from './MenuStepper';
import MenuStep from './MenuStep';
import MenuTitleTypography from './MenuTitleTypography';

interface CategoryProps {
    title: string;
    nav: LinkItem[];
    currentNav: number;
    number: number;
    currentState?: string;
    currentPresentation: Presentation | undefined;
    toggleDrawer?: () => void;
}
const iconSx = {
    margin: 1,
};

function Category({
    title,
    nav,
    currentNav,
    number,
    currentState,
    currentPresentation,
    toggleDrawer,
}: CategoryProps) {
    const [editMode] = useEditMode();
    const numberCategory: string = number.toString().padStart(2, '0');

    // Changement des couleurs en fonction du type de présentation séléctionné
    const presentationFlowType = getIdFromIri(
        currentPresentation?.presentationFlowId
    );
    const themePalette =
        presentationFlowType === 'location' ? 'rental' : 'sale';
    const presentationColorMain = (theme: Theme) =>
        theme.palette[themePalette].main;
    const presentationColorLight = (theme: Theme) =>
        theme.palette[themePalette].light;

    const menuStepSx = {
        ...(currentState === 'fullscreen' &&
            nav.length > 4 && {
                padding: '1px 8px',
            }),
        ...(currentState === 'fullscreen' &&
            nav.length > 5 && {
                padding: '0px 8px',
            }),
    };

    return (
        <Box
            data-testid="categoryMenu"
            sx={{
                marginBottom: '0.5rem',
                overflow: 'hidden',
                ...(currentState === 'fullscreen' && {
                    flexBasis: '31%',
                    marginTop: 0,
                    minheight: 'calc((100vh - 88px)/3)', // 88 = header (64) + padding Bottom of 3 Category (8 each)
                }),
                ...(currentState !== 'fullscreen' && {
                    flexBasis: '100%',
                }),
            }}
            m={1}
        >
            <LinearProgress
                color={themePalette}
                variant="determinate"
                value={9}
            />
            <Box
                data-testid={presentationFlowType}
                sx={{
                    display: 'flex',
                    marginTop: '1rem',
                }}
            >
                <Typography
                    variant="h4"
                    component="p"
                    sx={{
                        fontWeight: 'bold',
                        color: presentationColorMain,
                    }}
                >
                    {numberCategory}
                </Typography>
                <Box
                    sx={{
                        width: 'calc(100% - 1rem)',
                        marginLeft: '1rem',
                    }}
                >
                    <MenuTitleTypography color={presentationColorLight}>
                        {title}
                    </MenuTitleTypography>
                    <MenuStepper activeStep={currentNav} color={themePalette}>
                        {nav.map((item: LinkItem) => (
                            <Step key={item.link}>
                                <MenuStep
                                    sx={menuStepSx}
                                    to={`/presentation#${item.link}`}
                                    actions={
                                        !editMode ? null : (
                                            <>
                                                {!item.disabled ? (
                                                    <VisibilityIcon
                                                        sx={iconSx}
                                                    />
                                                ) : (
                                                    <VisibilityOffIcon
                                                        sx={iconSx}
                                                    />
                                                )}
                                                <StatusPage
                                                    content={item.content}
                                                    iconSx={iconSx}
                                                />
                                                <EditContentLink
                                                    content={item.content}
                                                    to={`/presentation#${item.link}`}
                                                    onClick={toggleDrawer}
                                                />
                                            </>
                                        )
                                    }
                                >
                                    {item.name}
                                </MenuStep>
                            </Step>
                        ))}
                    </MenuStepper>
                </Box>
            </Box>
        </Box>
    );
}

export default Category;
