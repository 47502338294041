import SubmitButton from '@acdc/shared/src/ui/SubmitButton';
import { Box, Stack } from '@mui/material';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import UserModel from '@acdc/shared/src/features/user/User.model';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import { ApolloError } from '@apollo/client';
import FormSkeleton from '@acdc/shared/src/ui/FormSkeleton';
import Dropzone from '@acdc/shared/src/ui/Dropzone';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import {
    acceptImage,
    megaBytesToBytes,
} from '@acdc/shared/src/utils/file-helpers';
import useUserFormInitialValues from './useUserFormInitialValues';
import useSubmitUserForm from './useSubmitUserForm';
import userFormSchema from './userFormSchema';

const imageMaxSize = megaBytesToBytes(5);

export interface UserFormValue {
    lastname: string;
    firstname: string;
    email: string;
    phone: string;
    profession: string;
    comment: string;
    photo: File[];
}

export interface UserFormProps {
    value?: DeepPartial<UserModel> | undefined;
    onSuccess?: ((res: UserResponse) => void) | undefined;
    onError?: ((err: ApolloError) => void) | undefined;
}

function UserForm({ value, onSuccess, onError }: UserFormProps) {
    const [initialValues, initialPhotos] = useUserFormInitialValues(value);
    const submit = useSubmitUserForm(value?.id, onSuccess, onError);

    if (!initialValues) {
        return <FormSkeleton nbInputs={6} />;
    }

    return (
        <Formik
            validationSchema={userFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            {({ values, handleChange }: FormikProps<UserFormValue>) => (
                <Form>
                    <Stack direction="column" spacing={2} minWidth={360}>
                        <Box>
                            <Dropzone
                                id="UserFormPhoto"
                                name="photo"
                                value={values.photo}
                                onChange={handleChange}
                                label="Photo de profil"
                                multiple={false}
                                accept={acceptImage}
                                initialApiFiles={initialPhotos}
                                maxSize={imageMaxSize}
                                clearable
                            />
                            <ErrorMessage
                                name="photo"
                                component={ErrorHelperText}
                            />
                        </Box>
                        <Stack direction="row" spacing={2}>
                            <Field
                                component={TextField}
                                id="lastnameInput"
                                label="Nom"
                                name="lastname"
                                required
                                disabled
                                size="small"
                                fullWidth
                            />
                            <Field
                                component={TextField}
                                id="firstnameInput"
                                label="Prénom"
                                name="firstname"
                                required
                                disabled
                                size="small"
                                fullWidth
                            />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Field
                                component={TextField}
                                id="emailInput"
                                label="Email"
                                name="email"
                                required
                                disabled
                                size="small"
                                fullWidth
                            />
                            <Field
                                component={TextField}
                                id="phoneInput"
                                label="Téléphone"
                                name="phone"
                                size="small"
                                fullWidth
                            />
                        </Stack>
                        <Field
                            component={TextField}
                            id="professionInput"
                            label="Métier"
                            name="profession"
                            size="small"
                            fullWidth
                        />
                        <Field
                            component={TextField}
                            id="commentInput"
                            label="Commentaire libre"
                            name="comment"
                            size="small"
                            fullWidth
                        />
                    </Stack>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <SubmitButton />
                    </Box>
                </Form>
            )}
        </Formik>
    );
}

export default UserForm;
